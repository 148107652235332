import React, { useEffect, useState, useContext } from "react";
import query from "jquery";
import { Link } from "react-router-dom";
import { WarehouseContext } from "../context/WarehouseContext";
import { useWarehouses } from "../hooks/useWarehouses";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { CategoryContext } from "../context/CategoryContext";
// import { useWarehouseData } from '../hooks/useWarehouseData';
// import {  useQueryClient } from '@tanstack/react-query';
const HeaderOne = () => {
  // const queryClient = useQueryClient();
  // const warehouses=queryClient.getQueriesData(['warehouses'])
  const { selectedWarehouse, changeWarehouse } = useContext(WarehouseContext);
  const { changeSubCategory, changeMainCategory } = useContext(CategoryContext);

  const { data: warehouses } = useWarehouses();
  const { data } = useWarehouseData(selectedWarehouse);
  // const locations=warehouses?.result?.data||['']
  const categories = data?.result?.parent_categories;
  const locations = warehouses?.result?.data;
  const products = data?.result?.products;
  // console.log(categories, "warehouseeeeeee");
  // const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const categories=warehouseData?.result?.parent_categories||['']
  // const handleSelect = (warehouse) => {
  //   changeWarehouse(warehouse);
  // //   closeModal();
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredData = products?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  function handleMainCategoryChange(e, id) {
    changeMainCategory(id);
    changeSubCategory(0);
  }
  const [scroll, setScroll] = useState(false);
  const handleChange = (event) => {
    console.log(event.target.value, "e.t.v");
    console.log("e.t.v");
    changeWarehouse(event.target.value);
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 150) {
        setScroll(false);
      } else if (window.pageYOffset > 150) {
      }
      return () => (window.onscroll = null);
    };
    const selectElement = query(".js-example-basic-single");
    selectElement.select2();

    return () => {
      if (selectElement.data("select2")) {
        selectElement.select2("destroy");
      }
    };
  }, []);
  // function handleSubCategoryChange(id){

  //     changeSubCategory(id)
  // }
  // Set the default language
  // const [selectedLanguage, setSelectedLanguage] = useState("Eng");
  // const handleLanguageChange = (language) => {
  //     setSelectedLanguage(language);
  // };

  // Set the default currency
  // const [selectedCurrency, setSelectedCurrency] = useState("USD");
  // const handleCurrencyChange = (currency) => {
  //     setSelectedCurrency(currency);
  // };

  // Mobile menu support
  // const menuActive = true;
  // const [menuActive, setMenuActive] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(null);
  // const handleMenuClick = (index) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };
  // const handleMenuToggle = () => {
  //   setMenuActive(!menuActive);
  // };

  // Search control support
  const [activeSearch, setActiveSearch] = useState(true);
  const handleSearchToggle = () => {
    setActiveSearch(!activeSearch);
  };

  // console.log(
  //   JSON.parse(localStorage.getItem("cart")).length,
  //   JSON.parse(localStorage.getItem("cart")),
  //   localStorage.getItem("cart")
  // );

  // category control support
  // const [activeCategory, setActiveCategory] = useState(false)
  // const handleCategoryToggle = () => {
  //     setActiveCategory(!activeCategory);
  // };
  // const [activeIndexCat, setActiveIndexCat] = useState(null);
  // const handleCatClick = (index) => {
  //     setActiveIndexCat(activeIndexCat === index ? null : index);
  // };

  return (
    <>
      <div className="overlay" />
      {/* <div className={`side-overlay ${menuActive && "show"}`} /> */}
      {/* ==================== Search Box Start Here ==================== */}
      {/* ==================== Search Box End Here ==================== */}
      {/* ==================== Mobile Menu Start Here ==================== */}
      {/* <div
        className={`mobile-menu scroll-sm d-lg-none d-none ${
          menuActive && "active"
        }`}
      >
        <button
          onClick={() => {
            handleMenuToggle();
            setActiveIndex(null);
          }}
          type="button"
          className="close-button"
        >
          <i className="ph ph-x" />{" "}
        </button>
        <div className="mobile-menu__inner">
          <Link to="/" className="mobile-menu__logo">
            <img
              src="../assets/images/logo/logo.svg"
              alt="Logo"
              style={{ height: "60px", width: "auto" }}
            />
          </Link>
          <div className="mobile-menu__menu d-none">
            {/* Nav Menu Start */}
      {/* Nav Menu End */}
      {/* </div>
        </div>
      </div> */}{" "}
      {/* ==================== Mobile Menu End Here ==================== */}
      {/* ======================= Middle Top Start ========================= */}
      <div className="header-top bg-main-600 flex-between d-none d-lg-block">
        <div className="container container-lg">
          <div className="flex-between flex-wrap gap-8">
            <ul className="flex-align flex-wrap d-none d-md-flex">
              <li className="border-right-item">
                <a
                  href="https://wa.me/919167999555"
                  target="_blank"
                  className="text-white text-sm hover-text-decoration-underline"
                  rel="noreferrer"
                >
                  <img
                    src="../assets/images/icon/Chat_Circle.svg"
                    alt=""
                    className="mb-1"
                  />{" "}
                  +91 919167999555
                </a>
              </li>
              <li className="border-right-item">
                <a
                  href="mailto:info@kifionline.com"
                  className="text-white text-sm hover-text-decoration-underline"
                >
                  <img
                    src="../assets/images/icon/Mail.svg"
                    alt=""
                    className="mb-1"
                  />{" "}
                  info@kifionline.com
                </a>
              </li>
            </ul>
            <ul className="header-top__right flex-align flex-wrap">
              <li className="on-hover-item border-right-item border-right-item-sm-space has-submen arrow-whit ">
                <Link to="#" className="text-white text-sm py-8">
                  Download Our Apps
                </Link>
                <img
                  src="../assets/images/icon/appstore.png"
                  alt=""
                  className="m-1"
                />
                <img src="../assets/images/icon/googleplay.png" alt="" />
              </li>
              <li className="on-hover-item border-right-item border-right-item-sm-space has-submen arrow-whit">
                {/* Display the selected language here */}
                <Link to="#" className="selected-text text-white text-sm py-8">
                  Refer & Earn
                </Link>
                {/* <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--sm max-h-200 scroll-sm px-0 py-8">
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("English")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag1.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Refer & Earn
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("Japan")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag2.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Didn't Find The Product ?
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("French")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag3.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            French
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("Germany")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag4.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Germany
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("Bangladesh")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag6.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Bangladesh
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleLanguageChange("South Korea")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag5.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            South Korea
                                        </Link>
                                    </li>
                                </ul> */}
              </li>
              <li className="on-hover-item border-right-item border-right-item-sm-space has-submen arrow-white">
                {/* Display the selected currency */}
                <Link to="#" className="selected-text text-white text-sm py-8">
                  {/* {selectedCurrency */}
                  Didn't Find The Product ?
                </Link>
                {/*
                  <ul className="selectable-text-list on-hover-dropdown common-dropdown common-dropdown--sm max-h-200 scroll-sm px-0 py-8">
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("USD")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag1.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            USD
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("Yen")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag2.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Yen
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("Franc")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag3.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            Franc
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("EURO")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag4.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            EURO
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("BDT")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag6.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            BDT
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="#"
                                            className="hover-bg-gray-100 text-gray-500 text-xs py-6 px-16 flex-align gap-8 rounded-0"
                                            onClick={() => handleCurrencyChange("WON")}
                                        >
                                            <img
                                                src="assets/images/thumbs/flag5.png"
                                                alt=""
                                                className="w-16 h-12 rounded-4 border border-gray-100"
                                            />
                                            WON
                                        </Link>
                                    </li>
                                </ul> */}
              </li>
              {/* <li className="border-right-item">
                                <Link
                                    to="/account"
                                    className="text-white text-sm py-8 flex-align gap-6"
                                >
                                    <span className="icon text-md d-flex">
                                        {" "}
                                        <i className="ph ph-user-circle" />{" "}
                                    </span>
                                    <span className="hover-text-decoration-underline">
                                        My Account
                                    </span>
                                </Link>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* ======================= Middle Top End ========================= */}
      {/* ======================= Middle Header Start ========================= */}
      <header
        className="header-middle  border-bottom border-gray-100"
        style={{ bgColor: "#67c0f0a6" }}
      >
        <div className="container container-lg">
          <nav className="header-inner flex-between">
            {/* Logo Start */}
            <div className="logo">
              <Link to="/" className="link">
                <img
                  src="../assets/images/logo/logo.svg"
                  alt="Logos"
                  style={{ height: "60px", width: "auto" }}
                />
              </Link>
            </div>
            {/* Logo End  */}
            {/* form location Start */}
            <form
              action="#"
              className="flex-align flex-wrap form-location-wrapper"
            >
              <div className="search-category d-flex h-48 select-border-end- radius-end- search-form d-sm-flex d-none">
                {/* <select defaultValue={1}
                                    className="js-example-basic-single border border-gray-200 border-end-0"
                                    name="state"
                                >
                                    <option value={1} >
                                        All Categoriesffrf
                                    </option>
                                    {categories?.map((cat)=>{return <option key={cat.id} value={cat?.id}>{cat?.name}</option>})}
                                </select> */}
                <div className="search-form__wrapper position-relative  ">
                  <input
                    type="text"
                    value={searchQuery}
                    id="search"
                    onChange={handleSearchChange}
                    className="search-form__input common-input py-13 ps-16 pe-18 pe-44 rounded-pill"
                    placeholder="Search for a products..."
                  />
                  <button
                    type="submit"
                    className="w-32 h-32 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
                  >
                    <i className="ph ph-magnifying-glass" />
                  </button>
                  <ul
                    className="shadow-sm w-100 rounded "
                    style={{
                      minHeight: "0px",
                      maxHeight: "150px",
                      overflowY: "auto",
                      backgroundColor: "white", // Ensure the ul background is solid white
                      position: "relative", // Optional but can help with layering issues
                      zIndex: 10,
                      // width: "300px",
                      listStyleType: "none",
                      margin: "0",
                    }}
                  >
                    {searchQuery.length > 0
                      ? filteredData?.map((item, i) => (
                          <li key={item.id} className="bg-white">
                            <Link
                              to={`/product-details/${item.id}`}
                              onClick={() => {
                                setSearchQuery("");
                              }}
                              className=" bg-white center mt-5 px-10 py-6 text-secondary text-center"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </form>
            {/* form location start */}
            {/* Header Middle Right start */}
            <div className="header-right flex-align d-lg-block d-non">
              <div className="flex-align flex-wrap gap-12">
                <button
                  type="button"
                  className="search-icon flex-align d-lg-none d-flex gap-4 item-hover d-lg-block d-none"
                >
                  <span className="text-2xl text-gray-700 d-flex position-relative item-hover__text">
                    <i className="ph ph-magnifying-glass" />
                  </span>
                </button>
                <Link className="flex-align gap-4 item-hover d-lg-block d-none">
                  {/* <span
                    className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text p-5 rounded-circle"
                    style={{ backgroundColor: "var(--bg-pale-blue)" }}
                  >
                   
                    <img
                      src="../assets/images/icon/Wishlist.svg"
                      alt="wishlist"
                    />
                    <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                      2
                    </span>
                  </span> */}
                  <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                    {/* Wishlist */}
                  </span>
                </Link>
                <Link
                  to="/cart"
                  className="flex-align gap-4 item-hover d-lg-block d-none"
                >
                  <span
                    className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text  --bg-color-two rounded-circle p-5 "
                    style={{ backgroundColor: "var(--bg-pale-blue)" }}
                  >
                    <img
                      src="../assets/images/icon/ShoppingCart.svg"
                      alt="shoping cart"
                    />
                    <span className="w-16 h-16 flex-center  m rounded-circle bg-main-600 text-white text-xs position-absolute top-n6 end-n4">
                      {localStorage.getItem("cart")
                        ? JSON.parse(localStorage.getItem("cart")).length
                        : 0}
                    </span>
                  </span>
                  <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                    {/* Cart */}
                  </span>
                </Link>
                <Link
                  to="/account"
                  className="flex-align gap-4 item-hover d-lg-block d-none"
                >
                  <span
                    className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text  --bg-color-two rounded-circle p-5 "
                    style={{ backgroundColor: "var(--bg-pale-blue)" }}
                  >
                    {/* <i className="ph ph-shopping-cart-simple" />
                     */}
                    <img
                      src="../assets/images/icon/User_acc.svg"
                      alt="shoping cart"
                    />
                  </span>
                  <span className="text-md text-gray-500 item-hover__text d-none d-lg-flex">
                    {/* Cart */}
                  </span>
                </Link>
                <div className="location-box bg-white flex-align gap-8 py-6 px-16 rounded-pill border-none border-gray-100">
                  <span className="text-gray-900 text-xl d-xs-flex d-none">
                    {/* <i className="ph ph-map-pin" /> */}

                    <img src="../assets/images/icon/Loc.svg" alt="" />
                  </span>
                  <div className="line-height-1">
                    <span className="text-gray-600 text-xs">Your Location</span>
                    <div className="line-height-1">
                      <select
                        value={selectedWarehouse}
                        onChange={handleChange}
                        className="js-example-basic-singl border border-0 bg-white border-end-0"
                        // name="state"
                      >
                        {locations?.map((loc) => {
                          if (loc.status === "active")
                            return (
                              <option key={loc?.id} value={loc?.id}>
                                {loc?.location_name}
                              </option>
                            );
                          else {
                            return "";
                          }
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Header Middle Right End  */}
          </nav>
        </div>
      </header>
      {/* ======================= Middle Header End ========================= */}
      {/* ==================== Header Start Here ==================== */}
      <header
        className={`header borderbottom border-none ${
          scroll && "fixed-header"
        }`}
        style={{ backgroundColor: "var(--bg-pale-blue)" }}
      >
        <div className="container container-lg">
          <nav className="header-inner d-flex justify-content-between gap-8">
            <div className="flex-align menu-category-wrapper">
              {/* Category Dropdown Start */}

              {/* Category Dropdown End  */}
              {/* Menu Start  */}
              <div className="header-menu d-lg-block d-none">
                {/* Nav Menu Start */}
                <ul className="nav-menu flex-align ">
                  {categories &&
                    categories.map((cat) => (
                      <li
                        className="on-hover-item nav-menu__item has-submen"
                        key={cat.id}
                      >
                        <Link
                          to="/shop"
                          role="button"
                          onClick={(e) => handleMainCategoryChange(e, cat.id)}
                          className="nav-menu__link d-flex gap-1"
                        >
                          <span className="text-xl d-flex">
                            <i className="ph ph-fish" />
                          </span>
                          <span>{cat.name}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
                {/* Nav Menu End */}
              </div>
              {/* Menu End  */}
            </div>
            {/* Header Right start */}
            <div className="header-right flex-align">
              <div className="me-16 d-lg-none d-block">
                <div className="flex-align flex-wrap gap-0">
                  <span
                    onClick={handleSearchToggle}
                    type="button"
                    className="search-icon flex-align d-lg-none d-flex gap-4 item-hover"
                  >
                    <span
                      style={{ width: "90vw" }}
                      className="text-2xl text-gray-700 d-flex position-relative item-hover__text"
                    >
                      {/* ----------------------------------------------- */}
                      {/* <i className="ph ph-magnifying-glass" /> */}
                      <form
                        action="#"
                        className={`search-bo my-4 ${activeSearch && "active"}`}
                        style={{ width: "100%" }}
                      >
                        <input
                          type="text"
                          value={searchQuery}
                          id="search"
                          placeholder="Search for a product..."
                          onChange={handleSearchChange}
                          className="form-control py-8 px-24  text-sm rounded-pill pe-64"
                        />
                        <button
                          type="submit"
                          className="w-40 h-40 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 "
                        >
                          <i className="ph ph-magnifying-glass" />
                        </button>
                        {/* </div>
                        </div> */}
                      </form>
                    </span>
                  </span>
                  <ul
                    className="shadow-sm bg-white rounded w-100"
                    style={{
                      minHeight: "0px",
                      maxHeight: "150px",
                      overflowY: "auto",
                      // padding: "8px",
                      width: "300px",
                      listStyleType: "none",
                      margin: "0",
                    }}
                  >
                    {searchQuery.length > 0
                      ? filteredData?.map((item, i) => (
                          <li key={item.id}>
                            <Link
                              to={`/product-details/${item.id}`}
                              onClick={() => {
                                setSearchQuery("");
                              }}
                              className="  center mt-5 px-10 py-6 text-secondary text-center"
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
            {/* Header Right End  */}
          </nav>
        </div>
      </header>
      {/* ==================== Header End Here ==================== */}
    </>
  );
};

export default HeaderOne;
