// import AddressBook from "./AddressBook";
import { useContext } from "react";
// import MyProfile from "./MyProfile";
// import AccountDashboard from "./AccountDashboard";
import { Link, Outlet } from "react-router-dom";
import AuthContext from "../context/AuthContext";
// import { addNewAddress, getUserAddress } from "../api/addressApi";

const OrderPage = () => {
  const { logout } = useContext(AuthContext);

  return (
    <>
      <div className="">
        <section className="">
          <div className="row d-flex">
            <section className="col-3">
              <div className=" pb-10">
                <h5 className="">MY ACCOUNT</h5>
                <ul className="list-none text-[15px] font-medium">
                  <li>
                    <Link to="dashboard" className={`text-black`}>
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="address" className={`text-black`}>
                      Address Book
                    </Link>
                  </li>
                  <li>
                    <Link to="t-and-c" className={`text-black`}>
                      Terms and Condition
                    </Link>
                  </li>
                  <li>
                    <Link to="edit-profile" className={`text-black`}>
                      Edit Profile
                    </Link>
                  </li>

                  <li
                    onClick={logout}
                    className="py-2 "
                    style={{ cursor: "pointer" }}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            </section>
            <section className="col-9  ">
              <Outlet />
            </section>
          </div>
        </section>
      </div>
    </>
  );
};

export default OrderPage;
