import React from "react";
import Modal from "react-modal";

import "./accountModule.css";
function ProfilePopup({ profileData, handleProfileChange, saveProfile }) {
  return (
    <Modal
      isOpen={true}
      //   onRequestClose={closeModal}
      contentLabel="Select Warehouse"
      className="popup pt-30 p-40"
      overlayClassName="warehouse-modal-overlay"
    >
      <div>
        <h2 className="hi">Complete Your Profile</h2>
        <input
          type="text"
          placeholder="Name"
          name="name"
          className="
          warehouse-dropdown w-100 h-100 text-field mb-10 mt-30 rounded-pill"
          value={profileData?.name}
          onChange={handleProfileChange}
        />
        <input
          className="text-field rounded-pill"
          type="number"
          placeholder="Mobile Number"
          name="phone1"
          value={profileData.mobile}
          onChange={handleProfileChange}
        />
        <input
          className="text-field rounded-pill"
          type="email"
          placeholder="Email"
          name="email"
          value={profileData.email}
          onChange={handleProfileChange}
        />
        <input
          className="text-field rounded-pill"
          type="text"
          placeholder="city"
          name="city"
          value={profileData.city}
          onChange={handleProfileChange}
        />
        <button className="close-modal-btn" onClick={saveProfile}>
          Save
        </button>
        {/* <button onClick={() => setShowPopup(false)}>Cancel</button> */}
      </div>
    </Modal>
  );
}
export default ProfilePopup;
