import React, { useEffect, useRef, useState } from "react";
import { getUserAddress } from "../api/addressApi";
import { NewAddress } from "./NewAddress";
// import { Link } from "react-router-dom";

function AccountDashboard() {
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [, setaddress] = useState(null);

  const handleUpdate = (e, address) => {
    e.preventDefault();
    if (address.type === "contact") {
      setIsProfileOpen(!isProfileOpen);
    } else {
      setIsFormOpen(!isFormOpen);
    }
    setaddress(address);
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const res = await getUserAddress();
        addressRef.current = res?.data?.result?.data?.address_list || [];
        // console.log(addressRef.current, res, "Fetched Addresses");
        setRenderTrigger(true);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [renderTrigger]);
  const addressRef = useRef([]);
  // const profileRef = useRef([]);

  const addr = addressRef.current.find((addr) => addr.type === "delivery");
  const profile = addressRef.current.find((addr) => addr.type === "contact");
  return (
    <div>
      <h5 className="fw-bolder">MY Dashboard</h5>
      <section>
        <p className="text-black mb-9">Hello {profile?.name}</p>
        <p>
          From your My Account Dashboard you have the ability to view a snapshot
          of your recent account activity and update your account information.
          Select a link below to view or edit information.
        </p>
      </section>
      <section className="mt-20">
        <p className="text-black fw-bold">ACCOUNT INFORMATION</p>
        <div className="mt-10">
          <div className="d-flex justify-content-between">
            <p>CONTACT INFORMATION</p>
            {/* <Link to="/account/address" state={{ profile: profile }}> */}

            <p onClick={(e) => handleUpdate(e, profile)}>Edit</p>
          </div>
          <hr />
          {
            <div style={{ marginBottom: "1rem" }} className="col-12 ">
              <p>
                <strong>Name:</strong> {profile?.name}
              </p>
              <p>
                <strong>Phone:</strong> {profile?.mobile}
              </p>
              <p>
                <strong>Email:</strong> {profile?.email}
              </p>
              <p>
                <strong>City:</strong> {profile?.city}
              </p>
            </div>
          }
          {/* <span>[NAME PHONE EMAIL...]</span> */}
        </div>
      </section>
      {isProfileOpen ? (
        <NewAddress address={profile} setIsProfileOpen={setIsProfileOpen} />
      ) : (
        ""
      )}

      <section className="mt-80">
        <div className="mt-10">
          <div className="d-flex justify-content-between">
            <p>ADDRESS BOOK</p>
            {/* <Link to={"/account/address"}>Edit</Link> */}
          </div>
          <hr />
          {addr ? (
            <div className="d-flex">
              <div>
                <p className="fw-bold text-black ">Default Billing Address</p>
                <div style={{ marginBottom: "1rem" }} className="col-12 ">
                  <p>
                    <strong>Name:</strong> {addr?.name}
                  </p>
                  <p>
                    <strong>Address:</strong> {addr?.address1}
                  </p>
                  <p>
                    <strong>City:</strong> {addr?.city}
                  </p>
                  <p>
                    <strong>Landmark:</strong> {addr?.landmark}
                  </p>
                  <p>
                    <strong>Postcode:</strong> {addr?.postcode}
                  </p>
                  <p>
                    <strong>State:</strong> {addr?.state}
                  </p>
                  {/* <p>
                  <strong>Country:</strong> {addr?.country}
                </p> */}
                  <p>
                    <strong>Email:</strong> {addr?.email}
                  </p>
                  <p>
                    <strong>Phone:</strong> {addr?.mobile}
                  </p>

                  {/* <p>
                  <strong>Type:</strong> {addr?.type}
                </p> */}
                </div>
              </div>
            </div>
          ) : (
            <p>please add new address from Address Book tab</p>
          )}
        </div>
      </section>
    </div>
  );
}

export default AccountDashboard;
