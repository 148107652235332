import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// import ReactSlider from 'react-slider'
// import { useQuery} from '@tanstack/react-query'
import ShopCard from "./ShopCard";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { WarehouseContext } from "../context/WarehouseContext";
import { CategoryContext } from "../context/CategoryContext";
const ShopSection = () => {
  const { selectedWarehouse } = useContext(WarehouseContext);
  const { data } = useWarehouseData(selectedWarehouse);
  const {
    selectedMainCategory,
    changeMainCategory,
    selectedSubCategory,
    changeSubCategory,
  } = useContext(CategoryContext);

  function handleMainCategoryChange(e, id) {
    changeMainCategory(id);
    changeSubCategory(0);
  }
  function handleSubCategoryChange(id) {
    changeSubCategory(id);
  }
  function handleCategoryAll(e) {
    e.preventDefault();
    changeSubCategory(null);
    changeMainCategory(null);
  }
  // console.log(selectedMainCategory,'cateeeeeeeeeee');
  const categories = data?.result?.parent_categories;
  const subcategories = selectedMainCategory
    ? data?.result?.child_categories.filter(
        (el) => el?.parent_category_id === selectedMainCategory
      )
    : data?.result?.child_categories;
  const products = data?.result?.products;

  // console.log(data?.result?.products.filter((item)=>item.website_categories[0].website_parent_category_id===state.id),'ddddddddddddd');

  let [grid, setGrid] = useState(false);

  let [active, setActive] = useState(false);
  let sidebarController = () => {
    setActive(!active);
  };

  return (
    <section className="shop pb-80">
      <div className={`side-overlay ${active && "show"}`}></div>
      <div className="container container-lg">
        <div className="row">
          {/* Sidebar Start */}
          <div className="col-lg-3">
            <div className={`shop-sidebar ${active && "active"}`}>
              <button
                onClick={sidebarController}
                type="button"
                className="shop-sidebar__close d-lg-none d-flex w-32 h-32 flex-center border border-gray-100 rounded-circle hover-bg-main-600 position-absolute inset-inline-end-0 me-10 mt-8 hover-text-white hover-border-main-600"
              >
                <i className="ph ph-x" />
              </button>

              <div className="shop-sidebar__box border border-gray-100 rounded-8 p-32 mb-32 mt-40">
                <h6 className="text-xl border-bottom border-gray-100 pb-24 mb-24">
                  Categories
                </h6>
                <ul className="max-h-540 overflow-y-auto scroll-sm">
                  {subcategories &&
                    subcategories?.map((item) => (
                      <li className="mb-24">
                        <div className="form-check common-check common-radio checked-black">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="color"
                            // checked={selectedSubCategory=== item.id}
                            id={`color${item.id}`}
                            onClick={() => handleSubCategoryChange(item.id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`color${item.id}`}
                          >
                            {item.name}
                          </label>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          {/* Sidebar End */}
          {/* Content Start */}
          <div className="col-lg-9">
            {/* Top Start */}
            <div className="flex-between gap-16 flex-wrap mb-40 d-none">
              {/* <span className="text-gray-900">Showing 1-20 of 85 result</span> */}
              <div className="position-relative flex-align gap-16 flex-wrap">
                <div className="list-grid-btns flex-align gap-16 d-none">
                  <button
                    onClick={() => setGrid(true)}
                    type="button"
                    className={`w-44 h-44 flex-center border rounded-6 text-2xl list-btn border-gray-100 ${
                      grid === true && "border-main-600 text-white bg-main-600"
                    }`}
                  >
                    <i className="ph-bold ph-list-dashes" />
                  </button>
                  <button
                    onClick={() => setGrid(false)}
                    type="button"
                    className={`w-44 h-44 flex-center border rounded-6 text-2xl grid-btn border-gray-100 ${
                      grid === false && "border-main-600 text-white bg-main-600"
                    }`}
                  >
                    <i className="ph ph-squares-four" />
                  </button>
                </div>
                <div className="position-relative text-gray-500 flex-align gap-4 text-14 ">
                  <label
                    htmlFor="sorting"
                    className="text-inherit flex-shrink-0 "
                  >
                    Sort by:{" "}
                  </label>
                  <select
                    defaultValue={1}
                    className="form-control border-0  common-input px-14 py-14 text-inherit rounded-6 w-auto"
                    id="sorting"
                    style={{ background: "#F4FAFF" }}
                  >
                    <option value={1}>Popular</option>
                    <option value={1}>Latest</option>
                    <option value={1}>Trending</option>
                    <option value={1}>Matches</option>
                  </select>
                </div>
                <button
                  onClick={sidebarController}
                  type="button"
                  className="w-44 h-44 d-lg-none d-flex flex-center border border-gray-100 rounded-6 text-2xl sidebar-btn"
                >
                  <i className="ph-bold ph-funnel" />
                </button>
              </div>
            </div>
            {/* Top End */}

            {/* ********************************************************************* */}

            <div>
              <div>
                <ul
                  className="pagination flex-end gap-16 mb-60 top-0 overflow-auto ms-"
                  style={{ whiteSpace: "nowrap", overflowX: "scroll" }}
                >
                  <li className="page-item">
                    <Link
                      className="page-link h-70 w flex-center text-md rounded-8 fw-medium text-neutral-600 border border-gray-100"
                      role="button"
                      onClick={(e) => handleCategoryAll(e)}
                      to="#"
                    >
                      {" "}
                      All{" "}
                    </Link>
                  </li>
                  {categories &&
                    categories.map((el) => (
                      <li className="page-item">
                        <Link
                          className="page-link h-70 w flex-center text-md rounded-8 fw-medium text-neutral-600 border border-gray-100"
                          role="button"
                          onClick={(e) => handleMainCategoryChange(e, el.id)}
                          to="#"
                        >
                          {el.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="d-flex gap-0 flex-wrap ">
                {
                  selectedSubCategory && selectedSubCategory !== 0
                    ? products?.map((el) => {
                        if (
                          el?.website_categories[0]?.website_category_id ===
                          selectedSubCategory
                        )
                          return <ShopCard item={el} />;
                        else return null;
                      })
                    : selectedMainCategory && selectedMainCategory !== 0
                    ? products?.map((el) => {
                        if (
                          el?.website_categories[0]
                            ?.website_parent_category_id ===
                          selectedMainCategory
                        )
                          return <ShopCard item={el} />;
                        else return null;
                      })
                    : products?.map((el) => <ShopCard item={el} />)

                  // products&&products.map((item)=>(<ShopCard item={item} />))
                }
              </div>
            </div>
          </div>
          {/* Content End */}
        </div>
      </div>
    </section>
  );
};

export default ShopSection;
