import React, { useContext } from "react";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { WarehouseContext } from "../context/WarehouseContext";

export default function Testimonial() {
  const { selectedWarehouse } = useContext(WarehouseContext);
  const { data } = useWarehouseData(selectedWarehouse);
  const testimonials = data?.result?.testimonials;
  console.log(data, "dataaaaaa", data);

  return (
    <>
      <div className=" d-lg-flex d-none gap-30 my-40 justify-content-between contain container-lg">
        {testimonials
          ? testimonials?.map((el) => (
              <img
                key={el.sl}
                //  src="assets/images/shape/testimony1.png"
                src={el.image_url}
                className="d-blok "
                alt="testimonial"
                style={{ width: "33%" }}
              />
            ))
          : ""}
        {/* <img
          src="assets/images/shape/testimony1.png"
          className="d-blok "
          alt="Slide 2"
          style={{ width: "33%" }}
        />
        <img
          src="assets/images/shape/testimony1.png"
          className="d-bock "
          alt="Slide 3"
          style={{ width: "33%" }}
        />
        <img
          src="assets/images/shape/testimony1.png"
          className="d-bloc "
          alt="Slide 1"
          style={{ width: "33%" }}
        /> */}
      </div>
      <div className=" d-lg-none d-flex flex-column container-lg mt-40 gap-20">
        {testimonials
          ? testimonials?.map((el) => (
              <img
                key={el.sl}
                //  src="assets/images/shape/testimony1.png"
                src={el.url ? el.image_url : ""}
                className="d-blok "
                alt="testimonial"
                style={{ width: "100%" }}
              />
            ))
          : ""}
      </div>
    </>
  );
}
