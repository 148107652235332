import React, { useContext } from "react";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { WarehouseContext } from "../context/WarehouseContext";
import Preloader from "../helper/Preloader";
import HeaderOne from "../components/HeaderOne";
import OfferZone from "../components/OfferZone";
import BannerTwo from "../components/BannerTwo";
import FooterOne from "../components/FooterOne";
import ScrollToTop from "react-scroll-to-top";
import ColorInit from "../helper/ColorInit";
import ProductListOne from "../components/ProductListOne";
import Taglines from "../components/Taglines";
import Category from "../components/Category";
import Footertop from "../components/Footertop";
import Testimonial from "../components/Testimonial";

const HomePageOne = () => {
  // -------------------------------------------------------
  const { selectedWarehouse } = useContext(WarehouseContext);
  const { isLoading, error } = useWarehouseData(selectedWarehouse);
  // -------------------------------------------------------
  // const [warehouse, setWarehouse] = useState("2");
  // const [locations, setLocations] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [warehouseChange,setWarehouseChange]=useState(false)
  // const [errors, setErrors] = useState(null);
  // console.log(products, "productsssss  from home1");

  if (isLoading) {
    return <Preloader />;
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      {/* Preloader */}
      <Preloader />
      {/* {console.log(warehouse,'prodooo')} */}
      {/* ScrollToTop */}
      <ScrollToTop smooth color="#299E60" />
      {/* login modal */}
      {/* {warehouseChange?<WarehouseModal setWarehouse={setWarehouse} loading={loading} locations={locations} error={errors} setSelectedLocation selectedLocation/>:""} */}

      {/* ColorInit */}
      <ColorInit color={false} />

      {/* HeaderOne */}
      {/* <HeaderOne categories={categories} locations={locations} warehouse={warehouse} setWarehouse={setWarehouse}  /> */}
      <HeaderOne />
      <BannerTwo />
      <Taglines />
      <ProductListOne />
      <Category />
      <OfferZone />
      <Testimonial />
      <Footertop />
      <FooterOne />
    </>
  );
};

export default HomePageOne;
