import React from "react";

function TandC() {
  return (
    <div className="w-full bg-white">
      <div className="w-full   h-screen bg-repeat-y overflow-y-scroll overflow-x-hidden ">
        <h1 className="font-poppins text-[40px] font-bold text-headinggray  my-20 ">
          Terms and Conditions
        </h1>
        <p className="font-poppins text-[15px] font-semibold text-headinggray mb-16">
          Helofish Supply Chain LLP ( Kifi ) is theowner of the brand Kifi and
          the website www.kifionline.com, the Kifi bmobileapplication on iOS and
          Android devices (together referred as ”Platform”).
        </p>

        <div className="mt-8 font-poppins mb-36 ">
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Customer Registration and Privacy Policy
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            The platform provides for registration process to serve its customer
            better and may now or in the future collect the following personally
            identifiable information to serve you better: a) Name including
            first and last name; b) email address; c) mobile phone numbers and
            contact details; d) Postal Pin code; e) Personal demographic profile
            like your age, gender, occupation, location, education, address
            etc.By completing the registration or placing an order, the Customer
            agrees to receive promotional and transactional communication, text
            messages, mobile notifications, phone calls and newsletters. The
            Customer can opt out by contacting the customer service at
            info@kifionline.com. We will never sell or provide your information
            to third party companies or outside agencies for commercial
            purposes. However, the platform may contain links to other web sites
            that we do not directly manage such as Google Analytics used to
            understand and optimize user’s behavior. Kifi shall not be
            responsible for the privacy policies of such external web sites.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Who Can Sign Up
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Any person can sign up for services provided if he or she is
            competent to enter a contract. Section 11 of the Indian Contract
            Act, 1872 specifies that every person is competent to contract
            provided he or she has attained the age of 18 years, is of sound
            mind and not disqualified from contracting by any other law to which
            he or she is subject to.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Customer Registration and Privacy Policy
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi grants customers limited access rights for personal use on this
            platform and not to download (other than page caching)or edit any
            portion of it.
            <br />
            <br />
            The access to this platform is for personal use of the Customer and
            not for any commercial use or to access its contents to collect and
            use of product listings, descriptions, or prices; any derivative use
            of this platform or any use of data mining, robots, or similar data
            gathering and extraction tools. No portions of this platform may be
            reproduced, duplicated, copied, sold, resold or otherwise exploited
            for any commercial purpose without express written consent of Kifi.
            <br />
            <br />
            Further no Customer is authorized to frame or utilize framing
            techniques to enclose any trademark, logo, or other proprietary
            information (including images, text, page layout, or form) on the
            platform without express written consent of Kifi.
            <br />
            <br />
            Any unauthorized use automatically terminates the permission granted
            by Kifi
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Pricing
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Pricesdisplayed for all the products on www.kifionline.com exclude
            GST.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Delivery and Handling Charges
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi may impose delivery and handling fees and taxes from time to
            time. Kifi endeavors to fulfil orders on time, but the actual
            delivery time may differ from the delivery time stated at the time
            of placing the order. Delivery delays will be communicated by Kifi.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Order Cancellation
          </h4>

          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi may impose delivery and handling fees and taxes from time to
            time. Kifi endeavors to fulfil orders on time, but the actual
            delivery time may differ from the delivery time stated at the time
            of placing the order. Delivery delays will be communicated by Kifi.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Return
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi believes in Centum Customer satisfaction and "no questions
            asked return and refund policy" for issues relating to quality or
            freshness of its supplies. Customer may request for a
            refund/replacement within 6 hours of delivery of the products in
            case of any quality issues.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            CustomerAcceptance
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            I hereby agree upon: To pay extra cost claimed by Kifi in the case
            of redelivery due to wrong name or address or any other wrong
            information provided while booking order. To use the platform to
            transact for lawful purposes in compliance with all applicable laws
            and regulation. To provide authentic and true information in all
            instances and authorize Kifi to validate the information provided at
            any point to time and reject registration if any details are found
            to be untrue wholly or partly. To access the services made available
            on the platform and to purchase the products offered at my own risk
            after using best and prudent judgement as a well-informed Customer.
            That the delivery address provided is correct and proper in all
            respects.That the product descriptions have been carefully checked
            before placing the order and agree to be bound by the terms and
            conditions of sale. That there may be excess / short quantity when
            ordering a cut SKU of a particular product. For example, the Steak
            version of a particular fish may have some variance in the final
            weight, given the fact it is hard to cut a fish in to an exact
            weight without damaging the shape of the Steak piece. In such
            instances,
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Obligations of Visitor / Customer
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            I hereby unconditionally undertake not to use the Platform for:
            Disseminating any objectionable material, harassing, libelous,
            abusive, threatening, harmful, vulgar, obscene, or any unlawful
            activity. To transmit material that constitutes a criminal offence
            or results in civil liability or otherwise breaches any relevant
            laws, regulations or code of practice. To gain unauthorized access
            to other computer network systems. Interfere with any other person's
            right to use or enjoyment of the platform. Breach of applicable
            laws. Interfere or disrupt networks or web sites connected to the
            platform. Make, transmit or store electronic copies of materials
            protected by copyright without the permission of Kifi. To post
            customer review feedback in violation of this policy or right of any
            third party, including copyright, trademark, privacy or other
            personal or proprietary right(s), and cause injury to any person or
            entity. To post comments containing libelous or otherwise unlawful,
            threatening, abusive or obscene material, or contain software
            viruses, political campaigning, commercial solicitation, chain
            letters, mass mailings or any form of "spam". Not to use a false
            email address, impersonate any person or entity, or otherwise
            mislead as to the origin of any Customer feedback submitted on the
            Platform. To be solely responsible for the content of any Customer
            Feedback made and agree upon to indemnify Kifi for all claims
            resulting from such Customer Feedback submitted.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Unsubscribe Process{" "}
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            By taking the steps below, you can choosenot to get any marketing or
            sales messages from us in the future. You can unsubscribe by
            emailing:info@kifionline.com or contact @ 9167999555 or by writing
            to Helofish SupplyChain LLP 15/809 H, Perumpilavu Karikkad P.O,
            Thrissur - 680519
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Pictures & Colors
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi has made every effort to display the products and its colors on
            the platform as accurately as possible. Kifi does not guarantee nor
            take responsibility for variations in pictorial representations for
            fresh foods and color variation due to technical reasons.
          </p>

          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Right to Modify Terms & Conditions of Service
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            Kifi reserves unconditional right to modify terms and conditions of
            transacting business on Kifi platform without any prior notification
            and consent of customers. When a registered customer accesses Kifi
            platform, he or she is deemed to have accepted the latest version of
            the Terms& Conditions on the Site.
          </p>
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Governing Law and Jurisdiction
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            All disputes arising out of or in doing business on the platform
            shall be amicably settled at the first instance by mutual
            discussions and negotiations. In the event the dispute is not
            resolved then the same may be referred to arbitration in accordance
            with the provisions of the Arbitration and Conciliation Act, 1996or
            any enactment of statutory modification thereof
          </p>
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Copyright& Trademark Rights
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            The Customer acknowledges that access to this platform does not
            confer and shall not be considered as conferring upon anyone any
            license under any of Kifi or any third party's intellectual property
            rights. Kifi expressly reserve all intellectual property rights in
            all text, programs, products, processes, technology, content, and
            other materials, which appear on the Platform. All rights, including
            copyright, on this platform is owned by Kifi. Any use of this
            platform or its contents, including copying or storing it either in
            whole or part, is prohibited without the permission of Kifi. The
            names and logos and all related product and service names, design
            marks and slogans are the trademarks or service marks of Kifi.
            References on Kifi Platform to any names, marks, products, or
            services of third parties or hypertext links to third party sites or
            information provided is solely for customer convenience and does not
            in any way constitute or imply Kifi endorsement, sponsorship or
            recommendation of the third party, information, product or service.
            Kifi is not responsible for content of any third-party sites and
            does not make any representations regarding the content or accuracy
            of material on such sites. Customers deciding to link to any such
            third-party websites, are doing entirely at their own risk. All
            materials, including images, text, illustrations, designs, icons,
            photographs, programs, music clips or downloads, video clips and
            written and other materials hosted on Kifi platform(collectively,
            the "Promotional Material") are intended solely for customer
            convenience. All software used in Kifi platform is the property of
            Kifi or its licensees and protected by copyright laws. The Contents
            and software in Kifi platform are to be used only as a shopping
            resource. Any other use, including there production, modification,
            distribution, transmission, republication, display of the Contents
            on this platform is strictly prohibited. All Contents are
            copyrights, trademarks, and/or other intellectual property owned,
            controlled, or licensed are by Kifi and its affiliates and are
            protected by copyright laws. The compilation (meaning the
            collection, arrangement, and assembly) of all Contents on this
            platform is the exclusive property of Kifi and is also protected by
            copyright laws.
          </p>
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Objectionable Material
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            While all IT security measures are taken to ensure wholesome and
            socially acceptable content are available on Kifi platform,
            notwithstanding the best efforts the customer understands and
            accepts the risk that while using this platform or any services
            provided on thewww.kifionline.com, one may encounter Content that
            may be deemed by some to be offensive, indecent, or objectionable,
            which Content may or may not be identified as such. Kifi and its
            affiliates shall have no liability to Customer for Content that may
            be deemed offensive, indecent, or objectionable to you
          </p>
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Indemnity
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            As a Customer, I hereby agree upon to defend, indemnify and hold
            harmless Kifi, its employees, directors, officers, agents and their
            successors and assigns from and against any and all claims
            ,liabilities, damages, losses, costs and expenses, including
            attorney's fees, caused by or arising out of claims based upon my
            actions or inactions, which may result in any loss or liability to
            Kifi or any third party including but not limited to breach of any
            warranties, representations or undertakings or in relation to the
            non-fulfilment of any obligations under this User Agreement or
            arising out of violation of any applicable laws, regulations
            including but not limited to Intellectual Property Rights, payment
            of statutory dues and taxes, claim of libel, defamation, violation
            of rights of privacy or publicity, loss of service to other
            subscribers and infringement of intellectual property or other
            rights. This clause shall survive the expiry or termination of this
            User Agreement. We may terminate this User Agreement at anytime,
            without notice or liability to Kifi
          </p>
          <h4 className=" font-semibold text-[18px]  text-headinggray my-6">
            Grievance Officer
          </h4>
          <p className="font-normal leading-[21px] mb-6  text-testinfo-gray">
            The contact details of the Grievance Officer are provided below:{" "}
            <br />
            Ms. Chithra M <br />
            Helofish Supply Chain LLP
            <br />
            Reg Office: 15/809 H,
            <br />
            Panchami Complex, Perumpilavu
            <br />
            Karikkad P.O, 680519 - Thrissur
            <br />
            Email: info@kifionline.com
            <br />
            Time: Mon - Sat (9:00 - 5:30)
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default TandC;
