// import React, { useState } from 'react';
import { Image } from "react-bootstrap";
import "./ProductPagelist.css";
import SubProductCard from "./SubProductCard";
// import { useLocation } from "react-router-dom";
import { useWarehouseData } from "../hooks/useWarehouseData";
import { WarehouseContext } from "../context/WarehouseContext";
import { useContext } from "react";

function ProductPagelist({ id }) {
  const { selectedWarehouse } = useContext(WarehouseContext);

  const { data } = useWarehouseData(selectedWarehouse);
  // const location = useLocation();
  // const { state } = location;
  const products = data?.result?.products;
  const productId = Number(id);
  const product = products?.find((item) => item.id === productId);
  // const product = products && products[0];

  // console.log(products, "-------------", product);
  return (
    <div className="container container-">
      <div className="mt-20 d-flex" style={{ color: "var(--secondary)" }}>
        All Product
        <p className="px-3 text-gray-300"> /{product?.name?.split("/")[0]}</p>
      </div>
      <div className="d-flex flex-wrap row">
        <div className="col-12 col-lg-6">
          <div className="product-details">
            <Image
              style={{
                paddingTop: "10px",
                marginTop: "18px",
                borderRadius: "20px",
                width: "475px",
                height: "300px",
              }}
              src={
                product?.image_url
                  ? `${product?.image_url}`
                  : `../assets/images/bg/testfish.png`
              }
              alt={"product name"}
              fluid
            />

            <h6 style={{ paddingTop: "20px" }}>{product?.name}</h6>
            {/* <p className="price">
              <span
                className="original-price"
                style={{ marginRight: "10px", fontWeight: "bold" }}
              >
                {product?.price}
              </span>
              <span className="discount" style={{ color: "green" }}>
                20% Off
              </span>
            </p> */}
            <div style={{ paddingTop: "10px" }}>{product?.description}</div>
            <p style={{ paddingTop: "10px" }}>
              <strong style={{ color: "#0047AB" }}>
                Storage Instructions:
              </strong>{" "}
              <br />
              Store under refrigeration at 4°C or below, in hygienic conditions.
            </p>
            <p style={{ paddingTop: "10px" }}>
              <strong style={{ color: "#0047AB" }}>Marketed By:</strong> <br />
              Corporate office: Door No: 15/809H, <br />
              Panchami Complex, Perumpilavu,Karikkad P.O., <br />
              680519,Thrissur
            </p>
            <p style={{ paddingTop: "10px" }}>
              <img
                src="https://restaurantindia.s3.ap-south-1.amazonaws.com/s3fs-public/2021-09/FSSAILOGO.jpg"
                alt="FSSAI Logo"
                style={{
                  width: "100px",
                  height: "68px",
                  display: "block",
                  marginBottom: "10px",
                }}
              />
              <strong>FSSAI Lic. No. 1122199900016</strong>
            </p>
            <div
              className="bg-main-50 border-radius-10 px-40 py-40 d-none d-lg-block mt-30"
              style={{ borderRadius: "10px" }}
            >
              <div
                className="text-main-600 "
                style={{
                  fontWeight: "600",
                  fontSize: "28px",
                  lineHeight: "30.2px",
                  letterSpacing: "1.2px",
                }}
              >
                Tastes best because <br />
                we deliver it fresh
              </div>
              <div className="mt-30 d-flex gap-20">
                <img src="../assets/images/shape/chemicalfree.svg" alt="" />
                <p
                  className=""
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "19.1px",
                  }}
                >
                  Enjoy pure, chemical-free goodness with our products. Taste
                  nature's best!
                </p>
              </div>
              <div className="mt-30 d-flex gap-20">
                <img src="../assets/images/shape/freshness.svg" alt="" />
                <p
                  className=""
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "19.1px",
                  }}
                >
                  freshness in every bite with our unfrozen food products.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <Col xs={10} md={8} style={{ flex: '0 0 65%' }}> */}
        <div className="col-lg-6 col-12">
          <div className="align-items-center">
            <h6 style={{ paddingTop: "20px" }}>{product?.name}</h6>
          </div>
          <div className="align-items-center">
            {product?.name}
            {product?.related_products?.map((el) => (
              <SubProductCard item={el} key={el.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPagelist;
