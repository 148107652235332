import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./checkout.module.css";
import { getUserAddress } from "../api/addressApi";
import OrderProgress from "./OrderProgress";
import CheckoutSidebar from "./CheckoutSidebar";
// import AddressBook from "./AddressBook";

const Checkout = () => {
  // const [selectedPayment, setSelectedPayment] = useState("payment1");

  const addressRef = useRef([]); // Store addresses
  const [, setRenderTrigger] = useState(false); // Trigger re-render
  const [homeDeliveryChecked, setHomeDeliveryChecked] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("");
  // const [deliveryAddress,setDeliveryAddress]=useState()

  const handleToggle = () => {
    setHomeDeliveryChecked(!homeDeliveryChecked);
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const res = await getUserAddress();
        addressRef.current = res?.data?.result?.data?.address_list || [];

        setRenderTrigger(true);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, []);
  const addr = addressRef.current.find((addr) => addr.type === "delivery");

  // const [total] = useState(
  //   cart.reduce((tot, item) => {
  //     return parseFloat((tot + item.item.price * item.num).toFixed(2));
  //   }, 0)
  // );
  const handleOptionChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <section className="checkout py-40">
      <div className="container container-lg ">
        <OrderProgress step={1} />
      </div>
      <div className="container container-lg d-flex">
        <div className="col-7">
          {/*----------Address------------- */}
          <div className="bg-main-50 rounded-10 px-20 py-10 pt-30 ">
            {/* <span className="font-weight-bold">Delivery Address</span>
          <hr /> */}
            <div className="d-flex justify-content-between">
              <p
                className="font-weight-bold"
                style={{
                  color: "#004781",
                  fontWeight: "600",
                  fontSize: "20px",
                  // lineHeight: "27.28px",
                }}
              >
                Billing & Shipping:
              </p>
              {/* <span className="text-main-700">Edit</span> */}
            </div>
            {addr ? (
              <div style={{ marginBottom: "1rem" }} className="col-12 ">
                <p>
                  <strong>Street:</strong> {addr?.address1},
                </p>

                <p>
                  <strong>Landmark:</strong> {addr?.landmark}
                </p>
                <p>
                  <strong>Postcode:</strong> {addr?.postcode}
                </p>
                <p>
                  <strong>Phone:</strong> {addr?.address2}
                </p>
              </div>
            ) : (
              <section
                className="checkout center py-40 d-flex flex-column"
                // style={{ color: "red" }}
              >
                <Link
                  to={"/account/address"}
                  className="btn btn-main mt-40 py-18  rounded-8"
                >
                  Add Delivery Address
                </Link>
              </section>
            )}
          </div>
          {/*------------Choose Delivery-------- */}
          <div
            className="my-30"
            style={{
              color: "#004781",
              fontWeight: "600",
              fontSize: "32.74px",
            }}
          >
            Choose Delivery
          </div>
          <div className="bg-main-50 rounded-10 px-20 py-20  ">
            <div className="d-flex gap-10">
              <input
                type="radio"
                id="toggleRadio"
                checked={homeDeliveryChecked}
                onChange={handleToggle} // Toggle the checked state
                style={{ display: "none" }} // Hide default radio button
              />
              <label
                htmlFor="toggleRadio"
                style={{
                  position: "relative",
                  paddingLeft: "30px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "black",
                  display: "inline-block",
                  lineHeight: "20px",
                }}
              >
                Home Delivery
                <span
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "20px",
                    height: "20px",
                    border: "2px solid #FF5E00",
                    borderRadius: "50%",
                    background: homeDeliveryChecked ? "#FF5E00" : "transparent",
                    transition: "background 0.3s ease",
                  }}
                />
              </label>
            </div>
          </div>
          <div className="d-flex gap-10 my-30">
            <input type="checkbox" />
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#24B43B",
              }}
            >
              Use Refferal Earnings
            </p>
          </div>
          <div>
            <div
              className="my-30"
              style={{
                color: "#004781",
                fontWeight: "600",
                fontSize: "32.74px",
                // lineHeight: "27.28px",
              }}
            >
              Payment Method
            </div>
            <div>
              <div>
                <label
                  style={{
                    position: "relative",
                    paddingLeft: "30px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "black",
                    display: "inline-block",
                    lineHeight: "20px",
                  }}
                >
                  <input
                    type="radio"
                    name="payment"
                    value="cod"
                    checked={paymentMethod === "cod"}
                    onChange={handleOptionChange}
                    style={{ display: "none" }}
                  />
                  <span className="radio-mark"></span>
                  <span
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                      width: "20px",
                      height: "20px",
                      border: "2px solid #FF5E00",
                      borderRadius: "50%",
                      background:
                        paymentMethod === "cod" ? "#FF5E00" : "transparent",
                      transition: "background 0.3s ease",
                    }}
                  />
                  Cash on Delivery
                </label>
              </div>

              <div>
                <label
                  style={{
                    position: "relative",
                    paddingLeft: "30px",
                    cursor: "pointer",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "black",
                    display: "inline-block",
                    lineHeight: "20px",
                  }}
                >
                  <input
                    type="radio"
                    name="payment"
                    value="paynow"
                    checked={paymentMethod === "paynow"}
                    onChange={handleOptionChange}
                    style={{ display: "none" }}
                  />
                  <span className="radio-mark"></span>
                  <span
                    style={{
                      position: "absolute",
                      left: "0",
                      top: "0",
                      width: "20px",
                      height: "20px",
                      border: "2px solid #FF5E00",
                      borderRadius: "50%",
                      background:
                        paymentMethod === "paynow" ? "#FF5E00" : "transparent",
                      transition: "background 0.3s ease",
                    }}
                  />
                  Pay Now
                </label>
              </div>
            </div>
          </div>

          <div>
            <Link
              to={"/shop"}
              type="submit"
              style={{
                border: "2px solid #004781",
                borderRadius: "15px",
                marginTop: "30px",
                backgroundColor: "transparent",
                padding: "10px 20px",
                color: "#004781",
              }}
            >
              {/* <i className="ph-bold ph-10" />
               */}
              <img src="/assets/images/icon/left_arrow.png" alt="" />
              Continue Shopping
            </Link>
          </div>
        </div>
        <CheckoutSidebar addr={addr} paymentMethod={paymentMethod} />
        {/* </div> */}

        {/* ------------------------------------------------- */}
      </div>
    </section>
  );
};

export default Checkout;
