import { BrowserRouter, Route, Routes } from "react-router-dom";
import RouteScrollToTop from "./helper/RouteScrollToTop";
import HomePageOne from "./pages/HomePageOne";
import PhosphorIconInit from "./helper/PhosphorIconInit";

import ShopPage from "./pages/ShopPage";
import ProductDetailsPageOne from "./pages/ProductDetailsPageOne";

import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import AccountPage from "./pages/AccountPage";

import ContactPage from "./pages/ContactPage";
import WarehouseSelector from "./components/WarehouseSelector";
import PaymentConfirmationPage from "./pages/PaymentConfirmationPage";
import AccountDashboard from "./components/AccountDashboard";
import AddressBook from "./components/AddressBook";
import TandC from "./components/TandC";
import EditProfile from "./components/EditProfile";

function App() {
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <PhosphorIconInit />
      <WarehouseSelector />
      <Routes>
        <Route exact path="/" element={<HomePageOne />} />
        <Route exact path="/web" element={<HomePageOne />} />
        <Route exact path="/shop" element={<ShopPage />} />
        <Route
          exact
          path="/product-details/:id"
          element={<ProductDetailsPageOne />}
        />

        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/checkout" element={<CheckoutPage />} />
        <Route exact path="/account" element={<AccountPage />}>
          <Route index element={<AccountDashboard />} />
          <Route path="address" element={<AddressBook />} />
          <Route path="dashboard" element={<AccountDashboard />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="t-and-c" element={<TandC />} />
        </Route>
        <Route
          exact
          path="/order-confirm"
          element={<PaymentConfirmationPage />}
        />
        <Route exact path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
