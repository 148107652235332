import React, { createContext, useState, useEffect } from "react";
import { addToRemotCart, UpdateRemoteCart } from "../api/cartApi";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    console.log(savedCart, "savedCart");

    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToLocalCart = (item, qty) => {
    let isItemExists = cart.some((el) => el?.product_id === item?.id);
    if (!isItemExists) {
      const product = {
        image_url: item.image_url,
        product_id: item.id,
        product_name: item.name,
        product_uom_qty: qty,
        price_unit: item.price,
        price_subtotal: item.price * qty,
      };
      // console.log(product, "iteeeeeeeeeeem");

      setCart((prevCart) => [...prevCart, product]);
    }
  };
  const updatetoRemoteCart = async (item, qty, saleOrderId) => {
    const cartToUpdate = [...cart, { product_id: item.id, quantity: qty }];
    await UpdateRemoteCart(saleOrderId, cartToUpdate);
  };

  const addToCart = async (item, qty) => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const saleOrederId = localStorage.getItem("saleOrderId");

    if (!isLoggedIn) {
      addToLocalCart(item, qty);
    } else {
      if (saleOrederId !== "undefined" && saleOrederId !== "null") {
        updatetoRemoteCart(item, qty, saleOrederId);
        addToLocalCart(item, qty);
      } else {
        await addToRemotCart(item.id, qty);

        addToLocalCart(item, qty);
      }
    }
  };

  const removeFromCart = async (item) => {
    const newcart = cart?.filter((e) => e.product_id !== item.product_id);
    setCart(newcart);
    localStorage.setItem("cart", JSON.stringify(newcart));

    // ----------------------------------
    const saleOrderId = localStorage.getItem("saleOrderId");

    const cartToUpdate = newcart.map((obj) => {
      return { product_id: obj.product_id, quantity: obj.product_uom_qty };
    });
    console.log("cartToUpdate", cartToUpdate);

    try {
      await UpdateRemoteCart(saleOrderId, cartToUpdate);
      // const newCart = await getRemoteCart(saleOrderId);
      // const orderLines = newCart?.data?.result?.sale_order?.order_lines;
      // localStorage.setItem("cart", JSON.stringify(orderLines));

      console.log("Cart synchronized successfully.");
    } catch (error) {
      console.error("Error synchronizing cart:", error);
    }
    // updateRemoteCart();
  };

  const increment = async (item) => {
    const saleOrderId = localStorage.getItem("saleOrderId");
    let updatedCart = cart.map((el) => {
      if (el.product_id === item.product_id) {
        return {
          ...el,
          product_uom_qty: el.product_uom_qty + 1,
          price_subtotal: el.product_uom_qty * el.price_unit,
        };
      }
      return el;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart)); // Persist change

    const res = await UpdateRemoteCart(saleOrderId, cart);
    console.log(res, "ressss");
  };

  const decrement = async (item) => {
    let updatedCart = cart.map((el) => {
      if (el.product_id === item.product_id) {
        return {
          ...el,
          product_uom_qty:
            el.product_uom_qty > 1
              ? el.product_uom_qty - 1
              : el.product_uom_qty,
          price_subtotal: el.product_uom_qty * el.price_unit,
        };
      }
      return el;
    });
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    const saleOrderId = localStorage.getItem("saleOrderId");

    await UpdateRemoteCart(saleOrderId, updatedCart);
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, increment, decrement, removeFromCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
