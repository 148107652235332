import React, { useEffect, useRef, useState } from "react";
import { NewAddress } from "./NewAddress";
import {
  changeDefaultAddress,
  // addNewAddress,
  deleteAddress,
  getUserAddress,
  // updateUserAddress,
} from "../api/addressApi";
import Preloader from "../helper/Preloader";

const AddressBook = () => {
  const addressRef = useRef([]); // Store addresses
  const [renderTrigger, setRenderTrigger] = useState(false); // Trigger re-render
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [address, setaddress] = useState(null);
  const [extraEntries, setExtraEntries] = useState([]);
  const [, setIsLoadig] = useState(false);
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const res = await getUserAddress();
        addressRef.current = res?.data?.result?.data?.address_list || [];
        console.log(addressRef.current, res, "Fetched Addresses");
        setRenderTrigger(true);
        setExtraEntries(() =>
          addressRef.current.filter(
            (el) => el.type !== "contact" && el.type !== "delivery"
          )
        );
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [renderTrigger]);
  console.log(extraEntries, "extraEntries");

  const addr = addressRef.current.find((addr) => addr?.type === "delivery");

  const handleUpdate = (e, address) => {
    e.preventDefault();
    setIsFormOpen(!isFormOpen);
    setaddress(address);
  };
  const handleSetAsDefault = async (address_id) => {
    setIsLoadig(true);
    await changeDefaultAddress(address_id);
    // window.location.reload();
    window.location.reload();
    setIsLoadig(false);
  };
  const handleDelete = (e, address_id) => {
    e.preventDefault();
    deleteAddress(address_id);
    window.location.reload();
  };
  return (
    <>
      <h5 className="fw-bolder">Address Book</h5>
      <button
        style={{ width: "300px" }}
        className="btn btn-main mt-40 py-18  rounded-8"
        onClick={(e) => {
          e.preventDefault();
          setIsFormOpen(!isFormOpen);
          setaddress(null);
        }}
      >
        Add New Address
      </button>
      <hr />
      {addressRef.current.length > 0 ? (
        <div>
          <div className="d-flex w-100 justify-content-between">
            <p className="fw-bold text-black">Delivery Address</p>
            <p
              className="font-weight-bold "
              onClick={(e) => handleUpdate(e, addr)}
            >
              Edit
            </p>
          </div>
          <hr />

          {addr ? (
            <div>
              <div style={{ marginBottom: "1rem" }} className="col-12 ">
                <p>
                  <strong>Name:</strong> {addr?.name}
                </p>
                <p>
                  <strong>Address:</strong> {addr?.address1}
                </p>
                <p>
                  <strong>City:</strong> {addr?.city}
                </p>
                <p>
                  <strong>Landmark:</strong> {addr?.landmark}
                </p>
                <p>
                  <strong>Postcode:</strong> {addr?.postcode}
                </p>
                <p>
                  <strong>Phone:</strong> {addr?.mobile}
                </p>
                <p>
                  <strong>Email:</strong> {addr?.email}
                </p>
              </div>
            </div>
          ) : (
            <p className="mb-10">add new Address</p>
          )}
          {/* <NewAddress /> */}
          {isFormOpen ? <NewAddress address={address} /> : ""}
          <p className="fw-bold text-black">Additional Address Entries </p>

          <hr />

          {extraEntries?.length !== 0 ? (
            <div className="d-flex flex-wrap">
              {/* {addressRef.current.length} */}
              {extraEntries?.map((addr, index) => (
                // <Address addr={addr} key={addr.address_id} />
                <div
                  style={{ marginBottom: "1rem" }}
                  className="col-6 "
                  key={addr?.id}
                >
                  <p>
                    <strong>Name:</strong> {addr?.name}
                  </p>
                  <p>
                    <strong>Address:</strong> {addr?.address1}
                  </p>
                  <p>
                    <strong>City:</strong> {addr?.city}
                  </p>
                  <p>
                    <strong>Landmark:</strong> {addr?.landmark}
                  </p>
                  <p>
                    <strong>Postcode:</strong> {addr?.postcode}
                  </p>
                  <p>
                    <strong>Phone:</strong> {addr?.mobile}
                  </p>
                  <p>
                    <strong>Email:</strong> {addr?.email}
                  </p>

                  <div className="mt-20 ">
                    {" "}
                    <button
                      className="me-20"
                      onClick={(e) => handleUpdate(e, addr)}
                    >
                      Update
                    </button>
                    <button
                      className="me-20"
                      onClick={(e) => handleSetAsDefault(addr.address_id)}
                    >
                      Set as Default Address
                    </button>
                    <button onClick={(e) => handleDelete(e, addr.address_id)}>
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              {" "}
              <p> add new Address</p>
            </div>
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AddressBook;
