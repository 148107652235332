// import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const OrderProgress = ({ step }) => {
  const stages = ["Review Order", "Selecting Address", "Confirm Order"];

  return (
    <div className="container mb-10">
      <div className="d-flex justify-content-between align-items-center position-relative">
        {/* Horizontal Line */}
        <div
          className="position-absolute"
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#E8E8E8",
            top: "50%",
            left: 0,
            zIndex: 1,
          }}
        ></div>

        {/* Stages */}
        {stages.map((stage, i) => (
          <div
            key={i}
            className="text-center position-relative mt-24"
            style={{ zIndex: 2 }}
          >
            {/* Radio Button */}

            <div>
              <input
                type="radio"
                id={`stage-${i}`}
                name="order-progress"
                checked={i === step}
                className="form-check-input"
                style={{
                  transform: "scale(1.2)",
                  position: "relative",
                  top: "-50%",
                }}
              />
            </div>
            <label
              htmlFor={`stage-${i}`}
              className="mt-"
              style={{
                color: "#004781",
              }}
            >
              {stage}
            </label>
            {/* Stage Label */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderProgress;
