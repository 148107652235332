import React, { createContext, useState } from "react";

export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addressList, setAddressList] = useState([]);
  // const [isProfileOpen,setIsProfileOpen]=useState(false)
  return (
    <AddressContext.Provider value={{ addressList, setAddressList }}>
      {children}
    </AddressContext.Provider>
  );
};
