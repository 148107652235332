import React, { useContext } from "react";
import PaymentGateway from "../helper/PaymentGateway";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import { UpdateSaleOrderStatus } from "../api/cartApi";
// import { addToRemotCart } from "../api/cartApi";

function CheckoutSidebar({ paymentMethod, addr }) {
  // const { cart } = useContext(CartContext);
  // const [finalCart] = useState(
  //   cart.map((el) => ({
  //     id: el.item.id,
  //     num: el.num,
  //   }))
  // );
  async function addtoCart() {
    const res = await UpdateSaleOrderStatus("sale");
    console.log(res, "response from ");

    // cart.forEach((el) => {
    //   if (el.item?.id && el.num) {
    //     addToRemotCart(el.item.id, el.num);
    //   } else {
    //     console.warn("Invalid cart item:", el);
    //   }
    // });
  }
  return (
    <div className="col-lg-5 ms-20 ">
      <div
        className="cart-sidebar borde border-gray-100 rounded-8 px-24 py-40"
        style={{ background: " #F4FAFF" }}
      >
        <Sidebar />
        <div
          className="flex-align  ga mt-20 cart-sidebar borde border-gray-100 rounded-8 
          "
          style={{ background: " #F4FAFF" }}
        >
          <input
            type="text"
            className="common-input bg-white py-18"
            placeholder="Coupon Code"
            style={{
              borderBottomLeftRadius: "13px",
              borderTopLeftRadius: "13px",
            }}
          />
          <button
            type="submit"
            className=" btn-main py-18 w-50 "
            // style={{}}
            style={{
              borderBottomRightRadius: "13px",
              borderTopRightRadius: "13px",
            }}
          >
            Apply
          </button>
        </div>

        {addr ? (
          paymentMethod === "cod" ? (
            <Link
              onClick={addtoCart}
              to="/order-confirm"
              className="btn btn-main mt-40 py-18 w-100 rounded-8"
            >
              Confirm Order{" "}
            </Link>
          ) : (
            <PaymentGateway />
          )
        ) : (
          <section
            className="checkout center py-40 d-flex flex-column"
            // style={{ color: "red" }}
          >
            <Link
              to={"/account/address"}
              // className="btn btn-main mt-40 py-18  rounded-8"
              className="btn btn-main mt-40 py-18 w-100 rounded-8"
            >
              Add Delivery Address
            </Link>
          </section>
        )}
      </div>
      <div></div>
    </div>
  );
}

export default CheckoutSidebar;

export const Sidebar = () => {
  const { cart } = useContext(CartContext);

  return (
    <div
      className="cart-sidebar borde border-gray-100 rounded-8 px-24 py-40 "
      style={{ background: " #F4FAFF" }}
    >
      <h6 className="text-xl mb-32">Order Total</h6>
      <div className="flex-between gap-24 mb-32">
        <div className="flex-align gap-12">
          <span className="text-gray-900 fw-normal text-md font-heading-two w-144">
            Product
          </span>
          <span className="text-gray-900 fw-normal text-md font-heading-two w-144 d-none d-lg-block"></span>

          <span className="text-gray-900 fw-semibold text-md font-heading-two">
            quantity
          </span>
        </div>
        <span className="text-gray-900 fw-bold text-md font-heading-two">
          price
        </span>
      </div>
      {cart?.map((item) => (
        <div className="flex-between gap-24 mb-32" key={item?.product_id}>
          <div className="flex-align gap-12">
            <span className="text-gray-900 fw-normal text-md font-heading-two w-144 d-none d-lg-block">
              <img
                src={
                  item?.image_url
                    ? `${item?.image_url}`
                    : `assets/images/bg/testfish.png`
                }
                className="rounded-10 w-120 "
                alt={item?.product_name}
              />
            </span>
            <span
              className="text-gray-900 fw-normal text-md font-heading-two w-144"
              style={{
                fontWeight: "600",
                lineClamp: "2",
                fontSize: "16px",
              }}
            >
              {item?.product_name}
            </span>
            <span className="text-gray-900 fw-normal text-md font-heading-two">
              {/* <i className="ph-bold ph-x" /> */}
            </span>
            <span className="text-gray-900 fw-semibold text-md font-heading-two">
              {item?.product_uom_qty}
            </span>
          </div>
          <span className="text-gray-900 fw-bold text-md font-heading-two">
            {(item?.price_subtotal).toFixed(2)}
          </span>
        </div>
      ))}
      <div className="bg-color- rounded-8 p-24 bg- border-top">
        <div className="mb-32 flex-between gap-8">
          <span className="text-gray-900 font-heading-two">Subtotal</span>
          <span className="text-gray-900 fw-semibold">
            ₹
            {cart.reduce((tot, item) => {
              return parseFloat((tot + item?.price_subtotal).toFixed(2));
            }, 0)}
          </span>
        </div>
        <div className="mb-32 flex-between gap-8">
          <span className="text-gray-900 font-heading-two">
            Extimated Delivery
          </span>
          <span className="text-gray-900 fw-semibold">Free</span>
        </div>
      </div>
      <div className="bg-color-thre rounded-8 p-24 mt-24 border-top">
        <div className="flex-between gap-8">
          <span className="text-gray-900 text-xl fw-semibold">Total</span>
          <span className="text-gray-900 text-xl fw-semibold">
            ₹
            {cart.reduce((tot, item) => {
              return parseFloat((tot + item?.price_subtotal).toFixed(2));
            }, 0)}
          </span>
        </div>
      </div>
    </div>
  );
};
