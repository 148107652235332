import React, { useContext, useState } from "react";
// import { Link } from "react-router-dom";
import { genarateOtp, verifyOtp } from "../api/UserAuth";
import AuthContext from "../context/AuthContext";
import AccountSidebar from "./AccountSidebar";
import ProfilePopup from "./ProfilePopup";
import { getUserAddress, updateAddress } from "../api/addressApi";

const Account = () => {
  // const {log}
  const { isLoggedIn, login } = useContext(AuthContext);
  const [responseData, setResponseData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState(null);
  const [, setFlag] = useState(null);
  // const [showPopup, setShowPopup] = useState(false);
  // const [addresses, setAddresses] = useState();
  const [verify, setverify] = useState(null);

  // ------------------------------------------------
  const [showPopup, setShowPopup] = useState(false); // Popup visibility
  const [profileData, setProfileData] = useState({
    id: "",
    name: "",
    email: "",
    phone1: "",
    city: "",
  });
  // Profile input data
  const fetchAddresses = async () => {
    try {
      const res = await getUserAddress();
      const addrss = res?.data?.result?.data?.address_list;
      // console.log(addrss, "addrss");

      const contact = addrss.find((addr) => addr.type === "contact") || [];
      if (contact?.mobile !== "" && contact?.email !== "") {
        setShowPopup(false);
      }
      const id = contact?.address_id;
      if (id) {
        setProfileData((prev) => ({ ...prev, id: id }));
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const saveProfile = async () => {
    try {
      const response = await updateAddress(profileData);
      if (response.status === 200) {
        setShowPopup(false);
        setFlag(response.status);
      } else {
        alert("Failed to save profile. Please try again.");
      }
      console.log(response, "profile editing ");
    } catch (error) {
      console.error("Error saving profile:", error);
    }
  };

  // console.log(profileData, "contaaact");

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };
  // -------------------------------------------------
  const handleLoginClick = async (e) => {
    e.preventDefault();
    setIsOtpSend(true);

    try {
      if (mobile.length !== 10) {
        console.error("Mobile Number is not valid");
        setErrorMessage("Mobile Number is not valid");
      } else {
        const data = await genarateOtp(mobile); // Call the API function

        setErrorMessage("");
        setResponseData(data?.errormessage); // Optionally store the response data in state
      }
    } catch (error) {
      console.error("Authentication Error:", error); // Handle error
      setErrorMessage("Authentication failed. Please try again.");
    }
  };
  const handleVerifyoOtp = async () => {
    try {
      if (mobile.length === 10 && otp.length === 6) {
        const data = await verifyOtp(mobile, otp);
        // console.log(data, "daaaaaaaaataaaaaaaaaa");

        setverify(data.errormessage);
        if (data?.data?.status === "verified") {
          login(data?.data?.user);
          // setAddresses(() => {});
          fetchAddresses();
          setShowPopup(true);
        }
      } else {
        //    throw new Error('invalid mobile number or otp');
        setErrorMessage("invalid mobile number or otp");
      }
    } catch (error) {
      console.error("Authentication Error:", error);
      setErrorMessage("Authentication failed. Please try again.");
    }
  };
  return (
    <section className="account py-80">
      <div className="container container-lg">
        <form action="#">
          {!isLoggedIn ? (
            <div className="row gy-4">
              {/* Login Card Start */}
              <div className="col-xl-6 pe-xl-5">
                <div className="border border-gray-100 hover-border-main-600 transition-1 rounded-16 px-24 py-40 h-100">
                  <h6 className="text-xl mb-32">Sign in</h6>
                  <div className="mb-24">
                    <label
                      htmlFor="mobile"
                      className="text-neutral-900 text-lg mb-8 fw-medium"
                    >
                      Mobile <span className="text-danger">*</span>{" "}
                    </label>
                    <div className="d-flex gap-5">
                      <input
                        type="number"
                        className="common-input"
                        id="mobile"
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-main py-18 px-40 no-wrap "
                        style={{ textWrap: "nowrap" }}
                        onClick={handleLoginClick}
                      >
                        Send Otp
                      </button>
                    </div>
                    <div>
                      {errorMessage && (
                        <div style={{ color: "red" }}>{errorMessage}</div>
                      )}
                      {responseData && (
                        <div style={{ color: "gray" }}>{responseData}</div>
                      )}
                    </div>
                  </div>
                  {isOtpSend ? (
                    <div>
                      <label
                        htmlFor="password"
                        className="text-neutral-900 text-lg mb-8 fw-medium"
                      >
                        Otp
                      </label>
                      <div className="mb-2 d-flex gap-5">
                        {/* <div className="position-relative"> */}
                        <input
                          type="number"
                          className="common-input"
                          placeholder="Enter Otp"
                          min="1"
                          max="6"
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                        {/* <span
                      className="toggle-password position-absolute top-50 inset-inline-end-0 me-16 translate-middle-y cursor-pointer ph ph-eye-slash"
                      id="#password"
                    /> */}
                        {/* </div> */}
                        <button
                          type="submit"
                          className="btn btn-main py-18 px-40"
                          style={{ textWrap: "nowrap" }}
                          onClick={handleVerifyoOtp}
                        >
                          Verify Otp
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    {verify && <div style={{ color: "gray" }}>{verify}</div>}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <AccountSidebar />
          )}
        </form>
      </div>
      {showPopup && (
        <ProfilePopup
          profileData={profileData}
          handleProfileChange={handleProfileChange}
          saveProfile={saveProfile}
        />
      )}
    </section>
  );
};

export default Account;
