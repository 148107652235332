import Preloader from "../helper/Preloader";
import ColorInit from "../helper/ColorInit";
// import Breadcrumb from "../components/Breadcrumb";

import BottomFooter from "../components/BottomFooter";
// import CartSection from "../components/CartSection";
// import ShippingOne from "../components/ShippingOne";
import ScrollToTop from "react-scroll-to-top";
import HeaderOne from "../components/HeaderOne";
import FooterOne from "../components/FooterOne";
import OrderProgress from "../components/OrderProgress";
// import { Sidebar } from "../components/CheckoutSidebar";
import Confirmation from "../components/Confirmation";

const PaymentConfirmationPage = () => {
  return (
    <>
      {/* ColorInit */}
      <ColorInit color={false} />

      {/* ScrollToTop */}
      <ScrollToTop smooth color="#299E60" />
      <Preloader />
      <HeaderOne category={true} />

      <OrderProgress step={2} />
      <Confirmation />
      <FooterOne />
      <BottomFooter />
    </>
  );
};

export default PaymentConfirmationPage;
