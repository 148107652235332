import React, { useEffect, useState } from "react";
import { Sidebar } from "./CheckoutSidebar";
import { getRemoteCart } from "../api/cartApi";

function Confirmation() {
  const [order, setOrder] = useState();
  const [userId] = useState(localStorage.getItem("userId"));
  const [saleOrder] = useState(localStorage.getItem("saleOrderId"));
  const [orderName, setOrderName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const respond = await getRemoteCart(saleOrder);
      console.log(respond, "reeesssspooonnnttt");
      const orderName = respond?.data?.result?.sale_order?.name;
      console.log(orderName, "reeesssspooonnnttt");

      setOrderName(orderName);
    };
    fetchData();
  }, [saleOrder]);
  useEffect(() => {
    if (userId)
      Promise.resolve(setOrder(localStorage.getItem("saleOrderId"))).then(
        localStorage.setItem("saleOrderId", undefined),
        localStorage.setItem("cart", "")
      );
  }, [userId]);
  // console.log(order,order);
  // if (userId === undefined) {
  //   return <div>add some products to cart</div>;
  // } else

  return (
    <section className="checkout py-40">
      {order !== "undefined" ? (
        <div className="container container-lg ">
          <div className="d-lg-flex d- ">
            <div className="flex-column col-md-7 ">
              <p
                className=""
                style={{
                  alignContent: "start",
                  fontWeight: "600",
                  color: "#004781",
                  fontSize: "24px",
                }}
              >
                Order:- &nbsp;{orderName}
              </p>
              <div className="w-100 d-flex justify-content-center mb-40">
                <img
                  className="w-50"
                  src="/assets/images/bg/order_confirm.svg"
                  alt=""
                />
              </div>
              <div
                className=" text-center d-flex  align-items-center justify-content-center"
                style={{
                  backgroundColor: "#004781",
                  height: "89px",
                  width: "90%",
                }}
              >
                <p
                  className="text-white "
                  style={{
                    fontSize: "24px",
                  }}
                >
                  Your order has received
                </p>
              </div>
              <p
                className="mt-20"
                style={{
                  alignContent: "start",
                  fontWeight: "600",
                  color: "#004781",
                  fontSize: "24px",
                }}
              >
                Payment Information
              </p>
            </div>
            <div className=" col-md-5">
              <Sidebar />
            </div>
          </div>
        </div>
      ) : (
        <div className="h-40 center ">Add Some Fish to cart</div>
      )}
    </section>
  );
}

export default Confirmation;
