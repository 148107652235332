import { useQuery } from "@tanstack/react-query";
import { fetchWarehouseData } from "../api/warehouseDataApi";

export const useWarehouseData = (warehouseId) => {
  return useQuery(
    ["warehouseData", warehouseId],
    () => fetchWarehouseData(warehouseId),
    {
      // enabled: !!warehouseId,
      staleTime: 2 * 60 * 1000,
    }
  );
};
